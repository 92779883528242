import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAppBar,{attrs:{"app":"","color":"white","dark":"","height":"80px","hide-on-scroll":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Cableclub Logo","contain":"","src":require("../assets/cableclub-logo.png"),"transition":"scale-transition","width":"70px"}})],1),_c(VCol,{staticClass:"d-flex align-end flex-column"},[_c(VBtn,{attrs:{"text":"","fab":""},on:{"click":_vm.exit}},[_c(VIcon,{attrs:{"large":"","color":"red"}},[_vm._v("mdi-exit-to-app")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }